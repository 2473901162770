import React from 'react';
import {useTranslation} from "react-i18next";
import Accordion from 'react-bootstrap/Accordion';
import DonateButton from "../components/donate";
import Helmet from "react-helmet";

function InfoSectionPage(props) {

    const { t, i18n } = useTranslation()

    return (
        <div className="container-fluid">

            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('about.us')}</title>
                <link rel="canonical" href="https://blog.lukyanchuk.com/categories" />
                <meta name="description" content="about us page" />
                <meta name="keywords" content="GrowthUp, Blog, News, Dev, Learn, Information, About Us, Study, Post, Tag, Posts, Dev blog, News, Categories, Tags, developer blog" />
                <meta name="author" content="Yaroslav Lukyanchuk" />
                <meta property="article:tag" content="Blog" />
                <meta property="article:tag" content="News" />
                <meta property="article:tag" content="Posts" />
                <meta property="article:tag" content="Inforamtion" />
                <meta property="article:tag" content="about us" />
            </Helmet>

            <div className="container p-2 pt-5">

                <div id="about-us" className={'mb-5'}>
                    <h2>{t('about.us')}</h2>
                    <p>
                        <b className={'display-5 lead'}> GrowthUp</b> - {t('info.about')}
                    </p>
                    <br/>
                    <DonateButton />
                    {/*<p className="lead">*/}
                    {/*    {t('nav.follow-us')}*/}
                    {/*</p>*/}
                    {/*<div className="d-flex flex-wrap">*/}
                    {/*    <a href="https://youtube.com/@YarikLukyanchuk" className={'p-1'} target={'_blank'}>*/}
                    {/*        YouTube*/}
                    {/*    </a>*/}
                    {/*    <a href="https://instagram.com/yariklukyanchuk/" className={'p-1'} target={'_blank'}>*/}
                    {/*        Instagram*/}
                    {/*    </a>*/}
                    {/*    <a href="https://twitter.com/yariklukyanchuk" className={'p-1'} target={'_blank'}>*/}
                    {/*        Twitter*/}
                    {/*    </a>*/}
                    {/*    <a href="https://t.me/yariklukyanchuk" className={'p-1'} target={'_blank'}>*/}
                    {/*        Telegram*/}
                    {/*    </a>*/}
                    {/*</div>*/}
                </div>

                <div id="team" className={'mb-5'}>
                    <h2> {t('team')}</h2>
                    <br/>
                    <div className="d-flex flex-wrap">

                        <div className="col-md-3">
                            <div className="border rounded p-4 pt-5 pb-4 m-1">
                                <h5>Name</h5>
                                <small className={'text-secondary'}>Author</small>
                                <br/>
                                <div className="text-secondary mt-3 d-flex align-items-center justify-content-between">

                                    <small className="text-secondary">Contacts:</small>

                                    <a href="#" className={'m-1'} target={'_blank'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                                            <path
                                                d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/*<div className="col-md-3">*/}
                        {/*    <div className="border rounded p-4 pt-5 pb-4 m-1">*/}
                        {/*        <h5>Yaroslav Lukyanchuk</h5>*/}
                        {/*        <small className={'text-secondary'}>Ярослав Лук'янчук</small>*/}
                        {/*        <br/>*/}
                        {/*        <div className="text-secondary mt-3 d-flex align-items-center justify-content-between">*/}

                        {/*            <small className={'text-secondary'}>CEO & Founder</small>*/}

                        {/*            <a href="https://linkedin.com/in/yaroslav-lukyanchuk-a028481ab/" className={'m-1'} target={'_blank'}>*/}
                        {/*                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"*/}
                        {/*                     fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">*/}
                        {/*                    <path*/}
                        {/*                        d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>*/}
                        {/*                </svg>*/}
                        {/*            </a>*/}
                        {/*            <a href="https://lukyanchuk.com" className={''} target={'_blank'}>*/}
                        {/*                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"*/}
                        {/*                     fill="currentColor" className="bi bi-heart" viewBox="0 0 16 16">*/}
                        {/*                    <path*/}
                        {/*                        d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/>*/}
                        {/*                </svg>*/}
                        {/*            </a>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*<div className="col-md-3">*/}
                        {/*    <div className="border rounded p-4 pt-5 pb-4 m-1">*/}
                        {/*        <h5>Bencion Krik</h5>*/}
                        {/*        <small className={'text-secondary'}>Бенціон Крик</small>*/}
                        {/*        <br/>*/}
                        {/*        <div className="text-secondary mt-3 d-flex align-items-center justify-content-between">*/}

                        {/*            <small className="text-secondary">Author Blog posts</small>*/}

                        {/*            <a href="#" className={'m-1'} target={'_blank'}>*/}
                        {/*                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"*/}
                        {/*                     fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">*/}
                        {/*                    <path*/}
                        {/*                        d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>*/}
                        {/*                </svg>*/}
                        {/*            </a>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*<div className="col-md-3">*/}
                        {/*    <div className="border rounded p-4 pt-5 pb-4 m-1">*/}
                        {/*        <h5>Bencion Krik</h5>*/}
                        {/*        <small className={'text-secondary'}>Бенціон Крик</small>*/}
                        {/*        <br/>*/}
                        {/*        <div className="text-secondary mt-3 d-flex align-items-center justify-content-between">*/}

                        {/*            <small className="text-secondary">Author News</small>*/}

                        {/*            <a href="#" className={'m-1'} target={'_blank'}>*/}
                        {/*                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"*/}
                        {/*                     fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">*/}
                        {/*                    <path*/}
                        {/*                        d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>*/}
                        {/*                </svg>*/}
                        {/*            </a>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                    </div>
                </div>

                <div id="careers" className={'mb-5'}>
                    <h2>{t('info.careers.in')} GrowthUp Blog & News</h2>
                    <br/>
                    <p>
                        {t('info.careers')}: <a href="mailto: hello@lukyanchuk.com">hello@lukyanchuk.com</a>
                    </p>
                </div>

                <DonateButton />

                {/*<div id="q&a" className={'mb-5'}>*/}
                {/*    <h2>Q&A</h2>*/}
                {/*    <Accordion>*/}
                {/*        <Accordion.Item eventKey="0">*/}
                {/*            <Accordion.Header>Accordion Item #1</Accordion.Header>*/}
                {/*            <Accordion.Body>*/}
                {/*                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do*/}
                {/*                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad*/}
                {/*                minim veniam, quis nostrud exercitation ullamco laboris nisi ut*/}
                {/*                aliquip ex ea commodo consequat. Duis aute irure dolor in*/}
                {/*                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla*/}
                {/*                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in*/}
                {/*                culpa qui officia deserunt mollit anim id est laborum.*/}
                {/*            </Accordion.Body>*/}
                {/*        </Accordion.Item>*/}
                {/*        <Accordion.Item eventKey="1">*/}
                {/*            <Accordion.Header>Accordion Item #2</Accordion.Header>*/}
                {/*            <Accordion.Body>*/}
                {/*                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do*/}
                {/*                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad*/}
                {/*                minim veniam, quis nostrud exercitation ullamco laboris nisi ut*/}
                {/*                aliquip ex ea commodo consequat. Duis aute irure dolor in*/}
                {/*                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla*/}
                {/*                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in*/}
                {/*                culpa qui officia deserunt mollit anim id est laborum.*/}
                {/*            </Accordion.Body>*/}
                {/*        </Accordion.Item>*/}
                {/*    </Accordion>*/}
                {/*</div>*/}

            </div>

        </div>
    );
}

export default InfoSectionPage;