import React from 'react';

function Breadcrumb(props) {
    return (
        <div className="breadcrumb-post container m-auto text-start p-5 pb-0">
            <a href={'/' + props.category + '/' + props.slug}>
                <div className="d-flex flex-wrap">
                    <h6 className={''}>🏠 https://blog.lukyanchuk.com</h6>
                    <h6 className={''}>/{props.category}</h6>
                    <h6 className={''}>/{props.slug}</h6>
                </div>
            </a>
        </div>
    );
}

export default Breadcrumb;