import './App.css';
import { Routes, Route} from "react-router-dom";
import SinglePage from "./pages/single";

import React from "react";
import Tamplate from "./tamplate";
import CategoryPostsPage from "./pages/category_posts";
import NavLinks from './components/routes/list';
import TagPostsPage from "./pages/tag_posts";
import PageMobileApp from "./pages/mobile_app";
function App() {

  return (

        <Routes>

            <Route path="/mobile-app" element={<PageMobileApp />} />

            <Route path="/:category/:post" element={<Tamplate child={<SinglePage />} />} />

            <Route path="/category/:name" element={<Tamplate child={<CategoryPostsPage />} />} />

            <Route path="/tag/:name" element={<Tamplate child={<TagPostsPage />} />} />

            {
                NavLinks.map((link, index) =>
                    <Route key={index} path={link.link} element={link.child} />
                )
            }

        </Routes>
  );
}

export default App;
