import React from 'react';
import {useTranslation} from "react-i18next";

function ContentPost(props) {

    const { t, i18n } = useTranslation()

    const content = i18n.language === 'en' ? props.content.en : props.content.ua;

    return (
        <div className="content-post p-3">
            <div dangerouslySetInnerHTML={{__html: content}} />
        </div>
    );
}

export default ContentPost;