import React from 'react';

function BannerADS() {
    return (
        <div className={'container'}>

            {/*<div className="p-2 mb-0 pb-0 pt-5">*/}
            {/*    <div className="border border-dark p-4">*/}
            {/*        <h1>Google Ads Box</h1>*/}
            {/*    </div>*/}
            {/*</div>*/}

        </div>
    );
}

export default BannerADS;