import React from 'react';
import {useTranslation} from "react-i18next";
import Helmet from "react-helmet";

function Page404(props) {

    const { t, i18n } = useTranslation()

    return (
        <div className="container-fluid">

            <Helmet>
                <meta charSet="utf-8" />
                <title>404 Page not found</title>
                <link rel="canonical" href="https://blog.lukyanchuk.com/" />
                <meta name="description" content="Posts page" />
                <meta name="keywords" content="GrowthUp, Blog, News, Error, Page not found, Dev, Learn, Study, Post, Tag, Posts, Dev blog, News, Categories, Tags, developer blog" />
                <meta name="author" content="Yaroslav Lukyanchuk" />
                <meta property="article:tag" content="error" />
                <meta property="article:tag" content="404" />
                <meta property="article:tag" content="page not found" />
            </Helmet>

            <div className="container p-2 pt-5">

                <div className="mt-5 vh-100">

                    <h1>Error... #404</h1>
                    <p className={'lead display-1'}> {t('no.found')}</p>

                    <a href="/" className={'btn btn-dark rounded-pill col-md-3 p-3 text-white'}>
                        Back Home
                    </a>

                </div>


            </div>

        </div>
    );
}

export default Page404;