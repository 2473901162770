import React, {useState, useEffect} from 'react';
import PostApi from './../../api/post_api';
import SquareADS from "../ads/square_ads";
import Pagination from "../pagination";
import PaginationComponent from "../pagination";
import LoadMoreSection from "../load_more";

function PostsByCategorySection(props) {

    const [posts, setPosts] = useState([]);
    const [pagination, setPagination] = useState([]);

    const setLocalData = (data) => {
        setPosts(data.posts)
        setPagination(data.pagination)
    }

    const fetchPostApi = (parameters) => {
        PostApi.getPosts(parameters, setLocalData)
    }

    const [page, setPage] = useState(1);

    useEffect(() => {
        fetchPostApi('?category=' + props.name + '&sort=DESC&onPage=6&page=' + page);
    }, [])

    const loadMore = (e) => {

        console.log('page ' + page)

        const newPage = page + 1;

        console.log('new page ' + newPage)
        setPage(newPage)

        console.log('begin post count: ' + posts.length)

        e.preventDefault();
        if(newPage >= pagination.page && newPage <= pagination.pages){
            PostApi.getPosts('?category=' + props.name + '&sort=DESC&onPage=6&page=' + newPage, (data) => {
                setPosts(posts => posts.concat(data.posts))
                setPagination(data.pagination)
            })

        }

        console.log('after post count: ' + posts.length)

    }

    return (
        <div className="">
            <div className="text-start p-2">
                <h1 className={'lead display-1'}>{
                    props.name.toUpperCase()
                }</h1>
            </div>

            <div className="d-flex flex-wrap">

                {
                    posts && posts.length > 0 && posts.map((post, index) => <div key={index} className="p-2 col-md-4 card-post">
                        <a href={'/' + post.category?.slug + '/' + post.slug} className={''}>
                            <div className="border border-dark text-start pb-4 shadow_">
                                <h4 className={'p-4'}><b className={'text-uppercase'}></b><span>{post.title?.en}</span></h4>
                                {post.image && <img src={post.image} alt="post-img" className={'img-fluid'}/>}
                                <p className={'p-4 pb-0'}>
                                    {/*{post.body.en.length > 100 ? post.body.en.substr(0, 100) + '...' : post.body.en}*/}
                                </p>
                                <span className="text-secondary pt-4 p-2">
                                {post.data}

                                <i> #{post.category?.name.en}</i>
                                    </span>
                            </div>
                        </a>
                    </div>
                    )
                }

                <div className="p-2 col-md-4">
                   {/*<SquareADS />*/}
                </div>

            </div>

            {
                pagination.pages !== page &&  <LoadMoreSection
                    funcLoadMore={loadMore}
                    page={page}
                    funcLocalUpdate={''}

                />
            }


            {/*<PaginationComponent pagination={pagination} funcGetData={fetchPostApi} />*/}
        </div>
    );
}

export default PostsByCategorySection;