import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PostApi from "../api/post_api";

function Search() {

    const {t, i18n} = useTranslation()

    const [show, setShow] = useState(false);

    const [results, setResults] = useState([]);
    const [query, setQuery] = useState();

    const getData = (parameters) => {
        try {

            PostApi.getPosts(parameters, (data) => setResults(data.posts))

        } catch (e) {
            console.log(e)
        }
    }

    const search = (e) => {
        if (query !== '' && e.key === 'Enter') {

            getData('?search=' + query)

        } else if ((query === '' && e.key === 'Enter') || (query === '' || e.key !== 'Enter')) {
            setResults([])
        }
    }

    useEffect(()=>{

    }, [])

    return (<div>
        <Button className={
            'bg-transparent border-0 text-secondary'
        } variant={localStorage.getItem('theme') === 'dark' ? 'light' : 'dark'} onClick={() => setShow(true)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 className="bi bi-search" viewBox="0 0 16 16">
                <path
                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
            </svg>
        </Button>

        <Modal
            show={show}
            onHide={() => setShow(false)}
            fullscreen={true}
            aria-labelledby="search-modal"
        >
            <Modal.Header closeButton className={'p-5'}  id={localStorage.getItem('theme')}>
                <Modal.Title id="search-modal" className={'display-6'}>
                    {t('search')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body  id={localStorage.getItem('theme')}>
                <div className="d-flex justify-content-center align-items-center pt-5 mt-5">
                    <input type="text" placeholder={t('search') + '...'} value={query}
                           onChange={(e) => setQuery(e.target.value)} onKeyDown={search}
                           className={'form-control_ p-2 border-0 border-bottom display-5 w-75'}/>
                </div>
                <div className="w-75 m-auto">
                    <span className="lead text-secondary">
                        {
                            query === '' ? '' : query !== '' && results.length === 0 ? t('no.found') : t('found') + ' ' + results.length + ' ' + t('posts')
                         }
                    </span>
                </div>
                <div className="w-75 d-flex flex-wrap m-auto">
                    {
                        results && results.length > 0 && results.map((post, index) => <div key={index}
                                                                                           className="p-2 col-md-4 card-post">
                            {/*<a href={'/' + JSON.parse(post.category.name)['en'].toLowerCase() + '/' + post.slug}*/}
                            <a href={'/' +  post.category.name.en.toLowerCase() + '/' + post.slug}
                               className={''}>
                                <div className="border border-dark text-start pb-4 shadow_">
                                    <h4 className={'p-4'}><b className={'text-uppercase'}></b>
                                        <span>{post.title.en}</span></h4>
                                    <span className="text-secondary p-4 pt-0">
                                {post.data} <i>#{post.category.name.en}</i>
                                    </span>
                                </div>
                            </a>
                        </div>)
                    }
                </div>
            </Modal.Body>
        </Modal>

    </div>);
}

export default Search;
