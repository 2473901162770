import React, {useEffect, useState} from 'react';
import CardPostRecently from "./posts/card-post-recently";
import SquareADS from "./ads/square_ads";
import PostApi from "../api/post_api";
import {useTranslation} from "react-i18next";
import DonateButton from "./donate";

function SideBar(props) {

    const { t, i18n } = useTranslation()

    const [load, setLoad] = useState(true);
    const [loadText, setLoadText] = useState('');
    const [posts, setPosts] = useState([]);

    const setLocalData = (data) => {
        setPosts(data.posts)
        window.localStorage.setItem('latest-posts', JSON.stringify(data))
    }

    const parameters = '&category=' + props.type;

    const fetchPostApi = () => {

        PostApi.getPosts('?onPage=5&sort=DESC' + parameters, setLocalData)
        console.log('load from server: ' + posts)
        setLoadText('Server')
    }

    const loadLatestPosts = () => {
        const data = window.localStorage.getItem('latest-posts');
        console.log('local: ' + JSON.parse(data))
        if(data == null){
            fetchPostApi();
        }else{
            const _data = JSON.parse(data);
            setPosts(_data.posts)
            setLoadText('Cache')
        }
    }

    useEffect(() => {
        if(props?.type === null || props.type !== 'Advertising'){
            // loadLatestPosts();
            fetchPostApi();
            setLoad(false)
        }

        const theme =  localStorage.getItem('theme');
        const div = document.getElementsByClassName('sidebar');

        // if(theme === 'dark'){
        //     div.classList.add('bg-dark')
        // }else{
        //
        // }

    }, [])

    if(props.type !== 'Advertising' && load){
        return <div>{loadText} Loading...</div>
    }

    return (
            <div className="side-bar border border-dark mt-2 m-2 sticky-top bg-white1 d-none d-sm-block">
                <div className={'p-3 pt-1 border-top- border-bottom- border-dark'}>
                    <h2 className={'mt-5'}>{props.title} {
                        (props.title === '' || props?.title === undefined) && t('latest') + ' ' + t('publications')}</h2>
                </div>
                <div className="post-by-category text-start mt-">
                    <div className="d-flex flex-wrap p-3 pt-0">
                        {
                            props.type === 'Advertising' ?
                                // <SquareADS />
                                <div></div>
                                : <div>
                                    {
                                        posts && posts.length > 0 && posts.map((post, index) =>  <CardPostRecently key={index} post={post} />)
                                    }
                                    <div className="border border-dark p-2 mt-5 text-center shadow-1">
                                        <span className={'text-uppercase m-auto'}><a href={props.type === '' ? '/posts' : '/category/' + props.type}>{t('browse.all')}</a></span>
                                    </div>
                                </div>
                        }
                    </div>
                </div>

            </div>
    );
}

export default SideBar;