import React, {useEffect, useState} from 'react';
import Footer from "../components/footer";
import Header from "../components/header";
import ThemeMode from '../storage/theme';
import Helmet from "react-helmet";
import {Routes} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import {useTranslation} from "react-i18next";

function Tamplate(props) {

    const { t, i18n } = useTranslation()

    const [theme, setTheme] = useState(ThemeMode.mode);

    const toggleTheme = () => {
        theme === 'light' ? setTheme('dark') : setTheme('light')

        if( theme === 'light'){
            ThemeMode.loadInit('dark', ()=>{})
        }else{
            ThemeMode.loadInit('light', ()=>{})
        }
    }

    useEffect(()=>{
        localStorage.setItem('theme', theme);
        ThemeMode.loadInit(theme, ()=>{})
        // document.body.className = theme;
    }, [theme])

    console.log('ThemeMode.mode: ' + ThemeMode.mode)

    useEffect(()=>{

        // i18n.changeLanguage(localStorage.getItem('lang') ?? 'en')
    }, [])

    return (
        <div className={'wrapper ' + theme}>

            <ScrollToTop />

            <Helmet>
                <meta name="google-adsense-account" content="ca-pub-4453172390299200" />
                <meta charSet="utf-8" />
                {/*<title>Main page</title>*/}
                <link rel="canonical" href="https://blog.lukyanchuk.com/" />
                <meta name="description" content="GrowthUp - developer blog, IT blog, News" />
                <meta name="keywords" content="GrowthUp, IT, Blog, News, Dev, Learn, Study, Post, Tag, Posts, Dev blog, News, Categories, Tags, developer blog" />
                <meta name="author" content="Yaroslav Lukyanchuk" />
                <meta property="article:tag" content="IT" />
                <meta property="article:tag" content="Blog" />
                <meta property="article:tag" content="News" />
                <meta property="article:tag" content="Laravel" />
                <meta property="article:tag" content="Flutter" />
                <meta property="article:tag" content="Git" />
                <meta property="article:tag" content="React JS" />
                <meta property="article:tag" content="Dev posts" />
                <meta property="article:tag" content="Dev blog" />
                <meta property="article:tag" content="developer posts" />
                <meta property="article:tag" content="developer blog" />

                <meta property="og:title" content={'GrowthUp Blog & News'}/>
                <meta name="og:description" content={'GrowthUp - developer blog, IT blog, News'}/>
                <meta property="og:url"
                      content={"https://blog.lukyanchuk.com/"}/>
                <meta property="og:image"
                      content={'https://blog.lukyanchuk.com/images/logo.png'}/>
                <meta property="og:type" content="page" />
                <meta property="og:image" content={'https://blog.lukyanchuk.com/images/logo.png'}/>
                <meta property="og:video" content="Your video URL"/>
                <meta property="og:audio" content="Your audio URL"/>
                <meta property="og:site_name" content="GrowthUp Blog & News"/>

                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content={'GrowthUp Blog & News'} />
                <meta name="twitter:description"  content={'GrowthUp - developer blog, IT blog, News'}/>
                <meta property="twitter:url"
                      content={"https://blog.lukyanchuk.com/"}/>
                <meta name="twitter:image" content={'https://blog.lukyanchuk.com/images/logo.png\''}/>
                <meta name="twitter:video" content="Your video URL" />
                <meta name="twitter:audio" content="Your audio URL" />

            </Helmet>

            <Header toggleTheme={toggleTheme} />

            <main>
                {
                    props.child
                }
            </main>

            <Footer/>
        </div>
    );
}

export default Tamplate;