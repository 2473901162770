import React, {useEffect, useState} from "react";
import {ArrowLeft, ArrowRight} from "react-bootstrap-icons";

const PaginationComponent = (props) => {

    //
    const onPage = props.pagination.on_page;
    // const page = props.pagination.page;
    const pages = props.pagination.pages;
    const total = props.pagination.total;

    const [page, setPage] = useState(1);

    const paginationChange = (e) => {

        e.preventDefault()
        console.log(e.target.value)

        props.funcGetData('?sort=DESC&onPage='+onPage+'&page=' + e.target.value);
        setPage(e.target.value)
    }

    const paginationNextChange = () => {

        const _page = parseInt(page) + 1;
        if(_page <= pages){
            props.funcGetData('?sort=DESC&onPage='+onPage+'&page=' + _page);
            setPage(_page)
        }
    }

    const paginationBackChange = () => {

        const _page = parseInt(page) - 1;

        if(_page > 0){
            props.funcGetData('?sort=DESC&onPage='+onPage+'&page=' + _page);
            setPage(_page)
        }
    }

    const pageButtons = [];

    let basicStyle = 'border border-dark p-2 text-center shadow-1';
    let activeStyle = basicStyle + ' bg-transparent';

    for (let i = 0; i < parseInt(pages); i++) {
        pageButtons.push(<button id={i} value={i+1} onClick={paginationChange} className={page === i+1 ? activeStyle : basicStyle}>
            {i+1}
        </button>)
    }

    return <div>
        {
            total > 1 && (<div>

                <div className="d-flex justify-content-between align-items-center border-top border-dark m-3 pt-3">
                    <div className={'col-md-10'}>

                        <div className="d-flex align-items-center">

                            <div className="prev col-md-1">
                                <button onClick={paginationBackChange} className={'border border-dark p-2 text-center shadow-1'}>
                                    <ArrowLeft size={20} color={'dark'}/>
                                </button>
                            </div>

                            <div className="d-flex flex-wrap col-md-10">
                                {
                                    pageButtons
                                }
                            </div>

                            <div className="next col-md-1">
                                <button onClick={paginationNextChange} className={'border border-dark p-2 text-center shadow-1'}>
                                    <ArrowRight size={20} color={'dark'}/>
                                </button>
                            </div>

                        </div>
                    </div>
                    <div className={'d-flex flex-column col-md-2'}>

                        <span className={'text-secondary'}>Page: <small className={'text-secondary'}>{page} / {pages}</small> </span>
                        {/*<span className={'text-secondary'}>Total: <b className={'text-dark'}>{total}</b> </span>*/}
                    </div>
                </div>
            </div>)
        }
    </div>
}

export default PaginationComponent;
