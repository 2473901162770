import React, {useEffect, useState} from 'react';
import Helmet from "react-helmet";
import BannerADS from "../components/ads/banner_ads";
import PostApi from "../api/post_api";
import SquareADS from "../components/ads/square_ads";
import LoadMoreSection from "../components/load_more";
import SideBar from "../components/sidebar";
import {useTranslation} from "react-i18next";

function PostsPage(props) {

    const { t, i18n } = useTranslation();

    const [posts, setPosts] = useState([]);
    const [pagination, setPagination] = useState([]);

    const setLocalData = (data) => {
        setPosts(data.posts)
        setPagination(data.pagination)
    }

    const fetchPostApi = (parameters) => {
        PostApi.getPosts(parameters, setLocalData)
    }

    const [page, setPage] = useState(1);

    useEffect(() => {
        fetchPostApi('?onPage=20&page=' + page);
    }, [])

    const loadMore = (e) => {

        console.log('page ' + page)

        const newPage = page + 1;

        console.log('new page ' + newPage)
        setPage(newPage)

        console.log('begin post count: ' + posts.length)

        e.preventDefault();
        if(newPage >= pagination.page && newPage <= pagination.pages){
            PostApi.getPosts('?onPage=20&page=' + newPage, (data) => {
                setPosts(posts => posts.concat(data.posts))
                setPagination(data.pagination)
            })

        }

        console.log('after post count: ' + posts.length)

    }

    return (
        <div className="">

            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('posts')}</title>
                <link rel="canonical" href="https://blog.lukyanchuk.com/posts" />
                <meta name="description" content="posts page" />
                <meta name="keywords" content="GrowthUp, Blog, News, Dev, Learn, Study, Post, Tag, Posts, Dev blog, News, Categories, Tags, developer blog" />
                <meta name="author" content="Yaroslav Lukyanchuk" />
                <meta property="article:tag" content="Blog" />
                <meta property="article:tag" content="News" />
                <meta property="article:tag" content="Posts" />


            </Helmet>

            <BannerADS />

            <div className="container text-start p-3">
                <h1 className={'lead display-1'}>Posts</h1>
            </div>

            <div className="category-post container d-flex">

                <div className="w-75">
                    <div className="d-flex flex-wrap">

                        {
                            posts && posts.length > 0 && posts.map((post, index) => <div key={index} className="p-2 col-md-4 card-post">
                                    <a href={'/' + post.category?.slug + '/' + post.slug} className={''}>
                                        <div className="border border-dark text-start pb-4 shadow_">
                                            <h4 className={'p-4'}><b className={'text-uppercase'}></b> <span>{post.title?.en}</span></h4>
                                            <div className="cover">
                                                <img src={post.image} alt="post-img" className={'img-fluid'}/>
                                            </div>
                                            <p className={'p-4 pb-0'}>
                                                <div dangerouslySetInnerHTML={{__html: post.body.en.length > 100 ? post.body.en.substr(0, 100) + '...' : post.body.en}} />
                                            </p>
                                            <span className="text-secondary pt-4 p-2">
                                {post.data}
                                                <i> #{post.category?.name.en}</i>
                                    </span>
                                        </div>
                                    </a>
                                </div>
                            )
                        }

                        <div className="p-2 col-md-4">
                            <SquareADS />
                        </div>

                    </div>

                    {
                        pagination.pages !== page &&  <LoadMoreSection
                            funcLoadMore={loadMore}
                            page={page}
                            funcLocalUpdate={''}

                        />
                    }
                </div>
                <div className="w-25 pt-5 sticky-top">
                    {/*<SquareADS />*/}
                    <SideBar title={t('latest') + ' ' + t('posts')} type={''}/>
                </div>

            </div>

        </div>
    );
}

export default PostsPage;