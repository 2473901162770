import React, {Suspense, useEffect, useRef, useState} from 'react';

import {useTranslation} from "react-i18next";
import API from '../api/request';
import DonateButton from "../components/donate";

const SideBar = React.lazy(() => import("../components/sidebar"));
const Popular = React.lazy(() => import("../components/section/popular"));
const SectionBlock = React.lazy(() => import("../components/section"));
const AppsBlock = React.lazy(() => import("../components/section/app"));
const Partners =  React.lazy(() => import("../components/partners"));

function useEffectOnce(effect) {
    const effectFn = useRef(effect)
    const destroyFn = useRef()
    const effectCalled = useRef(false)
    const rendered = useRef(false)
    const [, refresh] = useState(0)

    if (effectCalled.current) {
        rendered.current = true
    }

    useEffect(() => {
        if (!effectCalled.current) {
            destroyFn.current = effectFn.current()
            effectCalled.current = true
        }

        refresh(1)

        return () => {
            if (rendered.current === false) return
            if (destroyFn.current) destroyFn.current()
        }
    }, [])
}

function Main(props) {

    const { t, i18n } = useTranslation()

    useEffectOnce(() => {
        API.postRequest('detected', '', (data)=>{
            console.log(data)
        }, {})

        return () => {
            console.log("useEffectOnce exit");
        };
    })

    return (
        <div className="container-fluid">

            <div className="container p-2 pt-0">

                <div className="row mt-5">
                    <div className="col-md-3 p-1 text-start">

                        <Suspense fallback={<div>Loading...</div>}>
                            <SideBar title={t('news')} type={'news'}/>
                        </Suspense>

                        <div className="border border-dark shadow m-2 p-2 d-flex flex-wrap d-none d-sm-block">
                            {/*<a href="https://youtube.com/@YarikLukyanchuk" className={'p-1'} target={'_blank'}>*/}
                            {/*    YouTube*/}
                            {/*</a>*/}
                            {/*<a href="https://instagram.com/yariklukyanchuk/" className={'p-1'} target={'_blank'}>*/}
                            {/*    Instagram*/}
                            {/*</a>*/}
                            {/*<a href="https://twitter.com/yariklukyanchuk" className={'p-1'} target={'_blank'}>*/}
                            {/*    Twitter*/}
                            {/*</a>*/}
                            {/*<a href="https://t.me/yariklukyanchuk" className={'p-1'} target={'_blank'}>*/}
                            {/*    Telegram*/}
                            {/*</a>*/}

                            <DonateButton />
                        </div>

                        <Suspense fallback={<div>Loading...</div>}>
                            <SideBar title={t('latest') + ' ' + t('publications')} type={''}/>
                        </Suspense>

                        {/*<SideBar title={'News'} type={'news'}/>*/}

                        {/*<div className="border border-dark mt-5 m-2 p-5 d-none d-sm-block">*/}
                        {/*    <h1>Google Ads Box</h1>*/}
                        {/*</div>*/}

                        {/*<div className="border border-dark mt-5 m-2 p-1 d-none d-sm-block">*/}
                        {/*    <a href="https://billing.hostpro.ua/aff.php?aff=7643" className={'border border-dark'}><img src="https://billing.hostpro.ua/partners/uk/2021/general/300x600.png" alt="Hostpro" rel="nofollow" className={'img-fluid'}/></a>*/}
                        {/*</div>*/}

                    </div>
                    <div className="col-md-9 p-1 text-center">
                        {/*<div className="border border-dark m-2 p-4">*/}
                        {/*    <h1>Google Ads Box</h1>*/}
                        {/*</div>*/}

                        <Suspense fallback={<div>Loading...</div>}>
                            {/*<PopularSection />*/}
                            <Popular />
                        </Suspense>

                        <div className="d-block d-sm-none">
                            <Suspense fallback={<div>Loading...</div>}>
                                <SectionBlock type={'section'} title={'News'} category={'news'} />
                            </Suspense>
                        </div>

                        <div className="mt-5">

                            {/*<Suspense fallback={<div>Loading...</div>}>*/}
                            {/*    /!*<Section type={'section'} title={'Laravel'} category={'laravel'} />*!/*/}
                            {/*    <SectionBlock type={'section'} title={'Business'} category={'business'} />*/}
                            {/*</Suspense>*/}

                            {/*<Suspense fallback={<div>Loading...</div>}>*/}
                            {/*    /!*<Section type={'section'} title={'Laravel'} category={'laravel'} />*!/*/}
                            {/*    <SectionBlock type={'section'} title={'Politics'} category={'politics'} />*/}
                            {/*</Suspense>*/}

                            <Suspense fallback={<div>Loading...</div>}>
                                {/*<Section type={'section'} title={'Laravel'} category={'laravel'} />*/}
                                <SectionBlock type={'section'} title={'Laravel'} category={'laravel'} />
                            </Suspense>

                            {/*<Suspense fallback={<div>Loading...</div>}>*/}
                            {/*    <Partners />*/}
                            {/*</Suspense>*/}

                        </div>
                    </div>
                </div>

                {/*<Suspense fallback={<div>Loading...</div>}>*/}
                {/*    /!*<Section type={'section'} title={'Laravel'} category={'laravel'} />*!/*/}
                {/*    <SectionBlock type={'section'} title={'Cars'} category={'cars'} />*/}
                {/*</Suspense>*/}

                {/*<Suspense fallback={<div>Loading...</div>}>*/}
                {/*    /!*<Section type={'section'} title={'Laravel'} category={'laravel'} />*!/*/}
                {/*    <SectionBlock type={'section'} title={'Finance'} category={'finance'} />*/}
                {/*</Suspense>*/}


                <Suspense fallback={<div>Loading...</div>}>
                    {/*<Section type={'section'} title={'Flutter'} category={'flutter'} />*/}
                    <SectionBlock type={'section'} title={'Flutter'} category={'flutter'} />
                </Suspense>

                {/*<Suspense fallback={<div>Loading...</div>}>*/}
                {/*    <AppsBlock />*/}
                {/*</Suspense>*/}

                {/*<div className="border border-dark m-2 p-4">*/}
                {/*    <h1>Google Ads Box</h1>*/}
                {/*</div>*/}

                {/*<Suspense fallback={<div>Loading...</div>}>*/}
                {/*    /!*<Section type={'section'} title={'Laravel'} category={'laravel'} />*!/*/}
                {/*    <SectionBlock type={'section'} title={'Laravel'} category={'laravel'} />*/}
                {/*</Suspense>*/}

                {/*<Suspense fallback={<div>Loading...</div>}>*/}
                {/*    /!*<Section type={'section'} title={'Flutter'} category={'flutter'} />*!/*/}
                {/*    <SectionBlock type={'section'} title={'Flutter'} category={'flutter'} />*/}
                {/*</Suspense>*/}

                {/*<div className="border border-dark m-2 p-4">*/}
                {/*    <h1>Google Ads Box</h1>*/}
                {/*</div>*/}

                <Suspense fallback={<div>Loading...</div>}>
                    {/*<Section type={'section'} title={'React JS'} category={'react-js'} />*/}
                    <SectionBlock type={'section'} title={'React JS'} category={'react-js'} />
                </Suspense>

                <div className="mt-5 mb-5">

                        <div className="shadow-1 border border-dark p-2 mt-5 text-center col-md-3 m-auto">
                            <span className={'text-uppercase m-auto'}> <a href="/categories">{t('more')} {t('categories')}</a></span>
                        </div>

                </div>

                {/*<Suspense fallback={<div>Loading...</div>}>*/}
                {/*    <AppsBlock />*/}
                {/*</Suspense>*/}

                <div className="border border-dark m-2 p-4 text-center">
                    <a href="https://billing.hostpro.ua/aff.php?aff=7643"><img src="https://billing.hostpro.ua/partners/uk/2021/vps/728x90.png" alt="Hostpro" rel="nofollow" className={'img-fluid'}/></a>
                </div>

                {/*<PartnersBlock />*/}

            </div>

        </div>
    );
}

export default Main;