import React from 'react';
import CardPost from "../card";
import Section from "../section";
import {useTranslation} from "react-i18next";

function SimilarPost(props) {
    const { t, i18n } = useTranslation()
    return (
        <div className="similar-posts">
            <Section type={'section'} title={t('similar') + ' ' + t('posts').toLowerCase()} category={props.category} type={'similar-post'} />
        </div>
    );
}

export default SimilarPost;