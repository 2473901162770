import React, {useEffect, useState} from 'react';
import ThemeMode from "../storage/theme";

function CardPost(props) {

    const post = props.post;

    return (
       <div className={props.type === 'similar-post' ? "col-md-4 p-2" : "col-md-3 p-2"}>
           <div className={'card-post'}>
               <a href={'/' + post.category.name.en.toLowerCase() + '/' + post.slug} className={''}>
                   <div className="border border-dark text-start pb-4 shadow_">
                       <h4 className={'p-4'}><b className={'text-uppercase'}></b><span>{post.title.en}</span></h4>
                       <div className="cover">
                           {post.image && <img src={post.image} alt="post-img" className={'img-fluid'}/>}
                       </div>
                       <p className={'p-4 pb-0'}>
                           <div dangerouslySetInnerHTML={{__html: post.body.en.length > 100 ? post.body.en.substr(0, 100) + '...' : post.body.en}} />
                       </p>
                       <span className="text-secondary p-2 pt-4">
                                {post.data} <i>#{post.category.name.en}</i>
                                    </span>
                   </div>
               </a>
           </div>
       </div>
    );
}

export default CardPost;