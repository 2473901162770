import React from 'react';
import {useTranslation} from "react-i18next";

function SocialMedia(props) {

    const share = props.share;
    const { t, i18n } = useTranslation()

    return (
        <div className="social-media">
            <h5 className={'text-uppercase'}>{t('share')}:</h5>
            <div className="d-flex flex-wrap">
                {
                    Object.entries(share).map(([key,value], index) =>  <span key={index} className={'p-1'}><a href={value} className={'share-link'}>{key.toUpperCase()}</a></span>)
                }
            </div>
        </div>
    );
}

export default SocialMedia;