import React from 'react';

function ImagePost(props) {
    return (
        <div className="container-fluid mt-3">
            {/*??= 'https://via.placeholder.com/1920x1080'*/}
            {
                props.url && <img src={props.url} className={'shadow img-fluid post-image'} alt={"post-image_" + props.url}/>
            }
        </div>
    );
}

export default ImagePost;