import React from 'react';
import {useTranslation} from "react-i18next";
import {Calendar, Calendar2, CalendarFill, Eyeglasses, Newspaper, Person} from "react-bootstrap-icons";

function TitlePost(props) {
    const { t, i18n } = useTranslation()
    return (
        <div className="title-post container m-auto mt-3 text-start p-5 pt-0 pb-0">

            <div className="d-flex flex-wrap">
                <p className={'text-secondary'}>
                    {/*<span className={'text-dark'}> <Person/> {props.author} /</span>*/}
                    <small> <Calendar2 /> {props.data} / </small>
                    <small> <Newspaper/> #{props.category} /</small>
                    <small> <Eyeglasses/> {props.views}</small>
                </p>
            </div>

            <div className="d-flex flex-wrap mt-5">
                <small className={'text-uppercase- text-secondary'}>{t('title')}: </small>
                <h2 className={'text-dark'}>

                    {props.title}
                </h2>
            </div>
        </div>
    );
}

export default TitlePost;