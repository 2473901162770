import React, {useRef} from 'react';
import SideBar from "../components/sidebar";
import Helmet from "react-helmet";
import BannerADS from "../components/ads/banner_ads";
import {useParams} from "react-router";
import PostsByCategorySection from "../components/section/posts_by_category";
import PostsByTagSection from "../components/section/posts_by_tag";
import SquareADS from "../components/ads/square_ads";
import {useTranslation} from "react-i18next";

function TagPostsPage(props) {

    const {name} = useParams();

    const { t, i18n } = useTranslation()

    return (
        <div className={'container'}>


            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('tags')} #{name}</title>
                <link rel="canonical" href="https://blog.lukyanchuk.com/categories" />
                <meta name="description" content="posts page" />
                <meta name="keywords" content="GrowthUp, Blog, News, Dev, Learn, Study, Post, Tag, Tags, Posts, Dev blog, News, Categories, Tags, developer blog" />
                <meta name="author" content="Yaroslav Lukyanchuk" />
                <meta property="article:tag" content="Blog" />
                <meta property="article:tag" content="News" />
                <meta property="article:tag" content="Posts" />
                <meta property="article:tag" content="Tag" />
                <meta property="article:tag" content="Tags" />
                <meta property="article:tag" content={name} />
            </Helmet>

            <BannerADS />

            <div className="category-post container d-flex">

                <div className="w-75">
                    <PostsByTagSection name={name} />
                </div>
                <div className="w-25 pt-5">
                    <SideBar title={t('latest') + ' ' + t('publications')} type={''} category={name}/>
                    {/*<SquareADS />*/}
                </div>

            </div>

        </div>
    );
}

export default TagPostsPage;