import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import Footer from "../components/footer";
import Helmet from "react-helmet";

function PageMobileApp(props) {

    const {t, i18n} = useTranslation()
    return (
        <div className="container-fluid">

            <Helmet>
                <meta charSet="utf-8" />
                <title>Blog App</title>
                <link rel="canonical" href="https://blog.lukyanchuk.com/categories" />
                <meta name="description" content="mobile app page" />
                <meta name="keywords" content="GrowthUp, Blog, News, Dev, App, Mobile App, iOS, Android, Learn, Study, Post, Tag, Posts, Dev blog, News, Categories, Tags, developer blog" />
                <meta name="author" content="Yaroslav Lukyanchuk" />
                <meta property="article:tag" content="Blog" />
                <meta property="article:tag" content="News" />
                <meta property="article:tag" content="Posts" />
                <meta property="article:tag" content="App" />
                <meta property="article:tag" content="ios" />
                <meta property="article:tag" content="andoid" />
                <meta property="article:tag" content="mobile" />
                <meta property="article:tag" content="mobile app" />
                <meta property="article:tag" content="mobile application" />
            </Helmet>

            <header>
                <nav className="navbar navbar-expand-lg navbar-light fixed-top shadow-sm" id="mainNav">
                    <div className="container px-5 pb-3">
                        <a className="navbar-brand fw-bold" href="#page-top">
                            <spam>Blog App</spam>
                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarResponsive" aria-controls="navbarResponsive"
                                aria-expanded="false" aria-label="Toggle navigation">
                            Menu
                            <i className="bi-list"></i>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarResponsive">
                            <ul className="navbar-nav ms-auto me-4 my-3 my-lg-0">
                                <li className="nav-item"><a className="nav-link me-lg-3" href="#features">Features</a>
                                </li>
                            </ul>
                            <a className="btn btn-primary rounded-pill px-3 mb-2 mb-lg-0" href={'#download'}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-download text-white" viewBox="0 0 16 16">
                                    <path
                                        d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                    <path
                                        d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                                </svg>
                                <span className="small text-white">Download</span>

                            </a>
                        </div>
                    </div>
                </nav>
            </header>

            <main id={'page-top'} className={'container'}>

                <div className="vh-100 d-flex align-items-center justify-content-center">

                    <div className="title">
                        <img
                            src="https://www.vyaparinfotech.com/vi-viadmin/uploads/product/cat_pd_image/ios-app-development.png"
                            alt=""/>
                        {/*<img src="https://miro.medium.com/v2/resize:fit:1400/0*uaLSpSpp5DDgNLgH.gif" alt=""/>*/}
                        {/*<img src="https://assets.materialup.com/uploads/6d166c2c-dda5-451d-9b07-3383de47c135/preview.gif" alt="iphone" className={'img-fluid'}/>*/}
                        <br/>
                        <small>
                            GrowthUp App
                        </small>
                        <br/>
                        <h1>
                            Coming soon...
                        </h1>
                    </div>

                </div>

                <div id={'features'} className="vh-100">
                    <div>
                        <a className={'display-4 lead'}>
                            Features
                        </a>

                        <div className="d-flex align-items-center justify-content-center mt-5">


                            <div className="col-md-6 p-1">
                                <div className="p-">
                                    <img src="https://www.apple.com/newsroom/images/product/os/ios/standard/Apple_ios14-widgets-redesigned_06222020.jpg.news_app_ed.jpg"
                                         alt="animate" className={'img-fluid'}/>
                                </div>
                            </div>
                            <div
                                className="col-md-6 d-flex flex-wrap d-flex align-items-center justify-content-between">

                                <div className="col-md-6 p-1">
                                    <div className="p-2">
                                       <div className="d-flex align-items-center justify-content-center bg-white rounded-5 shadow-lg p-3">
                                           <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48"
                                                fill="currentColor" className="bi bi-bookmark-check m-2"
                                                viewBox="0 0 16 16">
                                               <path fill-rule="evenodd"
                                                     d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                                               <path
                                                   d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z"/>
                                           </svg>
                                           <small className={'lead m-1'}>
                                               Save content
                                           </small>
                                       </div>
                                    </div>
                                </div>

                                <div className="col-md-6 p-1">
                                    <div className="p-2">
                                        <div className="d-flex align-items-center justify-content-center bg-white rounded-5 shadow-lg p-3">
                                            <small className={'lead m-1'}>
                                                Available on
                                            </small>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48"
                                                 fill="currentColor" className="bi bi-apple m-2" viewBox="0 0 16 16">
                                                <path
                                                    d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282Z"/>
                                                <path
                                                    d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282Z"/>
                                            </svg>
                                            <div>
                                                &
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48"
                                                 fill="currentColor" className="bi bi-android2 m-2" viewBox="0 0 16 16">
                                                <path
                                                    d="m10.213 1.471.691-1.26c.046-.083.03-.147-.048-.192-.085-.038-.15-.019-.195.058l-.7 1.27A4.832 4.832 0 0 0 8.005.941c-.688 0-1.34.135-1.956.404l-.7-1.27C5.303 0 5.239-.018 5.154.02c-.078.046-.094.11-.049.193l.691 1.259a4.25 4.25 0 0 0-1.673 1.476A3.697 3.697 0 0 0 3.5 5.02h9c0-.75-.208-1.44-.623-2.072a4.266 4.266 0 0 0-1.664-1.476ZM6.22 3.303a.367.367 0 0 1-.267.11.35.35 0 0 1-.263-.11.366.366 0 0 1-.107-.264.37.37 0 0 1 .107-.265.351.351 0 0 1 .263-.11c.103 0 .193.037.267.11a.36.36 0 0 1 .112.265.36.36 0 0 1-.112.264Zm4.101 0a.351.351 0 0 1-.262.11.366.366 0 0 1-.268-.11.358.358 0 0 1-.112-.264c0-.103.037-.191.112-.265a.367.367 0 0 1 .268-.11c.104 0 .19.037.262.11a.367.367 0 0 1 .107.265c0 .102-.035.19-.107.264ZM3.5 11.77c0 .294.104.544.311.75.208.204.46.307.76.307h.758l.01 2.182c0 .276.097.51.292.703a.961.961 0 0 0 .7.288.973.973 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h1.343v2.182c0 .276.097.51.292.703a.972.972 0 0 0 .71.288.973.973 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h.76c.291 0 .54-.103.749-.308.207-.205.311-.455.311-.75V5.365h-9v6.404Zm10.495-6.587a.983.983 0 0 0-.702.278.91.91 0 0 0-.293.685v4.063c0 .271.098.501.293.69a.97.97 0 0 0 .702.284c.28 0 .517-.095.712-.284a.924.924 0 0 0 .293-.69V6.146a.91.91 0 0 0-.293-.685.995.995 0 0 0-.712-.278Zm-12.702.283a.985.985 0 0 1 .712-.283c.273 0 .507.094.702.283a.913.913 0 0 1 .293.68v4.063a.932.932 0 0 1-.288.69.97.97 0 0 1-.707.284.986.986 0 0 1-.712-.284.924.924 0 0 1-.293-.69V6.146c0-.264.098-.491.293-.68Z"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 p-1">
                                    <div className="p-2">
                                        <div className="d-flex align-items-center justify-content-center bg-white rounded-5 shadow-lg p-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48"
                                                 fill="currentColor" className="bi bi-graph-up-arrow m-2"
                                                 viewBox="0 0 16 16">
                                                <path fill-rule="evenodd"
                                                      d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z"/>
                                            </svg>
                                            <small className={'lead m-1'}>
                                                Current news
                                            </small>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 p-1">
                                    <div className="p-2">
                                        <div className="d-flex align-items-center justify-content-center bg-white rounded-5 shadow-lg p-3">
                                            <small className={'lead m-1'}>
                                                Personal Feed
                                            </small>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48"
                                                 fill="currentColor" className="bi bi-person-check-fill m-2"
                                                 viewBox="0 0 16 16">
                                                <path fill-rule="evenodd"
                                                      d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                                                <path
                                                    d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                <div id={'download'} className="vh-100">
                    <div>
                        <a className={'display-4 lead'}>
                            Get App
                        </a>

                        <div className="d-flex align-items-center justify-content-between mt-5">

                            <div className="col-md-6 p-1">
                                <img
                                    src="https://tehnomag.com/upload/catalog/product/5020/thumb/apple-iphone-14-plus-blue-a_63516ea26f734_650xr.png"
                                    alt="iphone-screen" className={'img-fluid'}/>

                            </div>
                            <div className="col-md-6 p-1">

                                <div className="row">
                                    <div className="col-md-6">

                                        <div className=" p-5 rounded-5 shadow-lg text-center">
                                            <img src="https://lukyanchuk.com/assets/qrcode/qr-code.png" alt="qr"
                                                 className={'img-fluid'}/>
                                            <small className={'text-secondary lead'}>AppStore</small>
                                        </div>

                                        <div className="mt-5">
                                            <a href="#thanks"> <img
                                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Download_on_the_App_Store_Badge.svg/800px-Download_on_the_App_Store_Badge.svg.png"
                                                alt="ios" className={'img-fluid shadow-lg '}/>
                                            </a>
                                        </div>

                                    </div>
                                    <div className="col-md-6">

                                        <div className=" p-5 rounded-5 shadow-lg text-center">
                                            <img src="https://lukyanchuk.com/assets/qrcode/qr-code.png" alt="qr"
                                                 className={'img-fluid'}/>
                                            <small className={'text-secondary lead'}>GooglePlay</small>
                                        </div>

                                        <div className="mt-5">
                                            <a href="#thanks">
                                                <img
                                                    src="https://upload.wikimedia.org/wikipedia/commons/5/5f/Google_Play_Store_badge_IT.svg"
                                                    alt={"android"} className={'img-fluid shadow-lg '}/>
                                            </a>
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>


                    </div>

                </div>

                <div id={'thanks'} className="text-center pt-5 mb-5 pb-5">
                    <div className={'test-secondary fw-bold- lead display-1'}>
                        Thanks for Download!
                    </div>
                </div>

            </main>

            <footer className="footer-section pb-4 pb-md-0 pt-3">
                <div
                    className="container border-top pt-5 1flex-row-reverse d-flex justify-content-between align-items-start p-5 pb-4 pt-0">
                    <div className="col-121 col-md-61">
                        <div className="text-secondary fw-bold lead">Blog App</div>
                        <small
                            className="text-secondary">
                            Made with ❤️ in 🇺🇦 by<a
                            href="https://lukyanchuk.com" className="text-decoration-none text-secondary"
                            target="_blank"> Yaroslav Lukyanchuk</a>.
                        </small>
                        <div className="mt-3 text-secondary"><small> {t('copyright')} {new Date().getFullYear()}.
                            Blog App. </small></div>
                    </div>
                    <div className="col-121 col-md-61">
                        <div className="text-secondary lead fw-bold">{t('information')}</div>
                        <div className="d-flex flex-column"><small><a className="text-decoration-none text-secondary"
                                                                      href="mailto:hello@lukyanchuk.com">hello@lukyanchuk.com</a>
                            <a href="https://t.me/YaroslavLukyanchuk" target="_blank"
                               className="text-decoration-none lead">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-telegram text-secondary m-2" viewBox="0 0 16 16">
                                    <path
                                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"></path>
                                </svg>
                            </a></small><small className="mt-2"><a href="/information"
                                                                   className="text-decoration-none text-secondary"
                                                                   target="_blank">{t('privacy.policy')} & {t('term-of-use')}</a></small>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default PageMobileApp;