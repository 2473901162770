import React from 'react';
import {useTranslation} from "react-i18next";
import Helmet from "react-helmet";

function InfoPage(props) {

    const { t, i18n } = useTranslation()

    return (
        <div className="container-fluid">

            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('information')}</title>
                <link rel="canonical" href="https://blog.lukyanchuk.com/categories" />
                <meta name="description" content="information page" />
                <meta name="keywords" content="GrowthUp, Blog, News, Dev, Information, Learn, Study, Post, Tag, Posts, Dev blog, News, Categories, Tags, developer blog" />
                <meta name="author" content="Yaroslav Lukyanchuk" />
                <meta property="article:tag" content="Blog" />
                <meta property="article:tag" content="News" />
                <meta property="article:tag" content="Posts" />
                <meta property="article:tag" content="Inforamtion" />
                <meta property="article:tag" content="Contacts" />
                <meta property="article:tag" content="site map" />
                <meta property="article:tag" content="api" />
            </Helmet>

            <div className="container p-2 pt-5">

                <div className="mt-5 vh-50">
                    <h1 className={'lead display-1'}>{t('information')}</h1>
                    <div className="d-flex flex-wrap">
                        <div className="col-md-4 p-2 mt-5 text-start">
                            <h2>GrowthUp
                                <br/> <small className={'text-secondary lead'}>Blog & News</small>
                            </h2>
                            <a href="/information/p/#about-us" className={'info-link'} target={'_blank'}>{t('about.us')}</a> <br/>
                            {/*<a href="/information/p/#team" className={'info-link'} target={'_blank'}>{t('team')}</a> <br/>*/}
                            {/*<a href="/information/p#careers" className={'info-link'} target={'_blank'}>{t('careers')}</a> <br/>*/}
                            {/*<a href="/mobile-app" className={'info-link'} target={'_blank'}>{t('Mobile App')}</a> <br/>*/}


                            {/*<a href="https://api.blog.lukyanchuk.com/app" className={'info-link'} target={'_blank'}>{t('Android App')}</a>*/}
                        </div>

                        {/*<div className="w-25 p-2 mt-5 text-start">*/}
                        {/*    <h2> {t('team')} </h2>*/}
                        {/*    <a href="/information/p/#team" className={'info-link'} target={'_blank'}>{t('team')}</a> <br/>*/}
                        {/*    <a href="/information/p#careers" className={'info-link'} target={'_blank'}>{t('careers')}</a> <br/>*/}
                        {/*</div>*/}

                        {/*<div className="col-md-4 p-2 mt-5 text-start">*/}
                        {/*    <h2> Privcay & Terms </h2><br/>*/}
                        {/*    <a href="/information/privacy_policy.pdf" className={'info-link'} target={'_blank'}>{t('privacy.policy')}</a> <br/>*/}
                        {/*    <a href="/information/term_of_use.pdf" className={'info-link'} target={'_blank'}>{t('terms.of.use')}</a> <br/>*/}

                        {/*</div>*/}

                        {/*<div className="w-25 p-2 mt-5 text-start">*/}
                        {/*    <h2>{t('App')}</h2>*/}
                        {/*    <a href="https://api.blog.lukyanchuk.com/app" className={'info-link'} target={'_blank'}>{t('iOS App')}</a> <br/>*/}
                        {/*    <a href="https://api.blog.lukyanchuk.com/app" className={'info-link'} target={'_blank'}>{t('Android App')}</a>*/}
                        {/*</div>*/}

                        {/*<div className="w-50 p-2 mt-5 text-start">*/}
                        {/*    <a href="https://billing.hostpro.ua/aff.php?aff=7643"><img src="https://billing.hostpro.ua/partners/uk/2021/vps/728x90.png" alt="Hostpro" rel="nofollow" className={'img-fluid'}/></a>*/}
                        {/*</div>*/}

                        <div className="col-md-4 p-2 mt-5 text-start">
                            <h2>{t('contacts')}</h2><br/>
                            <a href="mailto:hello@lukyanchuk.com" className={'info-link'} target={'_blank'}>hello@lukyanchuk.com</a>
                            {/*<br/>*/}
                            {/*<a href="tel.:+38 (098) 075 1357" target={'_blank'}>+38 (098) 075 1357</a>*/}
                        </div>

                        {/*<div className="w-50 p-2 mt-5 text-start">*/}
                        {/*    <a href="https://billing.hostpro.ua/aff.php?aff=7643"><img src="https://billing.hostpro.ua/partners/uk/2021/vps/728x90.png" alt="Hostpro" rel="nofollow" className={'img-fluid'}/></a>*/}
                        {/*</div>*/}

                        {/*<div className="w-25 p-2 mt-5 text-start">*/}
                        {/*    <h2>{t('support')}</h2><br/>*/}
                        {/*    <a href="/information/p/#q&a" className={'info-link'} target={'_blank'}>{t('q.a')}</a> <br/>*/}
                        {/*    <a href="mailto:support@blog.lukyanchuk.com" className={'info-link'} target={'_blank'}>support@blog.lukyanchuk.com</a>*/}
                        {/*</div>*/}

                        <div className="col-md-4 p-2 mt-5 text-start">
                            {/*<h2>{t('developers')}</h2><br/>*/}
                            <h2>{t('nav.other')}</h2><br/>
                            {/*<a href="https://blogapi.lukyanchuk.com/developer/docs" className={'info-link'} target={'_blank'}>{t('api.docs')}</a> <br/>*/}
                            {/*<a href="https://blogapi.lukyanchuk.com/developer/backlog" className={'info-link'} target={'_blank'}>{t('backlog')}</a>*/}

                            <a href="/site-map" className={'info-link'} target={'_blank'}>{t('site-map')}</a> <br/>

                            <a href={ '/info/files/' + i18n.language.split('-')[0] + '_privacy_policy.pdf'} className={'info-link'} target={'_blank'}>{t('privacy.policy')}</a> <br/>
                            <a href={ '/info/files/' + i18n.language + '_term_of_use.pdf'} className={'info-link'} target={'_blank'}> {t('terms.of.use')}</a> <br/>

                        </div>

                        {/*<div className="col-md-6 p-2 mt-5 text-start">*/}
                        {/*    <a href="https://billing.hostpro.ua/aff.php?aff=7643"><img src="https://billing.hostpro.ua/partners/uk/2021/vps/728x90.png" alt="Hostpro" rel="nofollow" className={'img-fluid'}/></a>*/}
                        {/*</div>*/}
                    </div>
                </div>

            </div>

        </div>
    );
}

export default InfoPage;