import React, {Suspense, useEffect, useState} from 'react';
import Section from "../components/section";
import ContentPost from "../components/posts/content-post";
import SideBar from "../components/sidebar";
import SocialMedia from "../components/social-media";
import TagsPost from "../components/posts/tags-post";
import SimilarPost from "../components/posts/similar-post";
import Breadcrumb from "../components/posts/breadcrumb";
import TitlePost from "../components/posts/title";
import ImagePost from "../components/posts/image";
import PostApi from "../api/post_api";
import Helmet from "react-helmet";
import BannerADS from "../components/ads/banner_ads";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import SquareADS from "../components/ads/square_ads";

function SinglePage(props) {

    const {t, i18n} = useTranslation()

    const {category, post} = useParams();

    const [postSingle, setPost] = useState(null);
    const [load, setLoad] = useState(true);

    const fetchSinglePost = async (slug) => {

        try {

            await PostApi.getSinglePost(slug, (post) => setPost(post))

        } catch (e) {
            console.log(e.message)
        }

        setLoad(false)
    }

    useEffect(() => {
        fetchSinglePost(post)
    }, [])

    if (load) {
        return <div className={'m-auto w-50 p-5'}> Loading... </div>
    }

    const seoDescription = <div
        dangerouslySetInnerHTML={{__html: postSingle.body.en.length > 100 ? postSingle.body.en.substr(0, 100) + '...' : postSingle.body.en}}/>;

    return (
        <div className={'single-post container'}>

            <Helmet>
                <title>{i18n.language === 'en' ? postSingle.title.en : postSingle.title.ua}</title>
                <meta name="description" content={seoDescription}/>
                <meta name="keywords"
                      content="GrowthUp, Blog, News, Dev, Learn, Study, Post, Tag, Posts, Dev blog, News, Categories, Tags, developer blog"/>
                <meta name="author" content={postSingle.author}/>
                {
                    postSingle.tags && postSingle.tags.length > 0 && postSingle.tags.split(',').map((tag, index) =>
                        <meta key={index} property="article:tag" content={tag}/>)
                }
                <meta property="og:title" content={postSingle.title.en}/>
                <meta name="og:description" content={seoDescription}/>
                <meta property="og:url"
                      content={"https://blog.lukyanchuk.com/" +  postSingle.category.slug + "/" +postSingle.slug}/>
                <meta property="og:image"
                      content={postSingle.image}/>
                <meta property="og:type" content="article" />
                <meta property="og:image" content={postSingle.image}/>
                <meta property="og:video" content="Your video URL"/>
                <meta property="og:audio" content="Your audio URL"/>
                <meta property="og:site_name" content="GrowthUp Blog & News"/>

                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content={postSingle.title.en} />
                <meta name="twitter:description"  content={seoDescription}/>
                <meta property="twitter:url"
                      content={"https://blog.lukyanchuk.com/" +  postSingle.category.slug + "/" +postSingle.slug}/>
                <meta name="twitter:image" content={postSingle.image}/>
                <meta name="twitter:video" content="Your video URL" />
                <meta name="twitter:audio" content="Your audio URL" />

            </Helmet>

            <Breadcrumb category={postSingle.category.slug ?? '-'} slug={postSingle.slug}/>

            {/*<div className="p-2 mb-0 pb-0 pt-5">*/}
            {/*    <BannerADS />*/}
            {/*</div>*/}

            <ImagePost url={postSingle.image}/>

            <TitlePost title={i18n.language === 'en' ? postSingle.title.en : postSingle.title.ua}
                       category={postSingle.category.slug} data={postSingle.data} author={postSingle.author}
                       views={postSingle.views}/>

            <div className="post container d-flex row">

                <div className="col-md-9 col-12 pt-5">

                    {/*<div className="p-5 mb-0 pb-0 pt-5">*/}
                    {/*    <BannerADS/>*/}
                    {/*</div>*/}
                    <div className="p-md-5 p-2">
                        <ContentPost content={postSingle.body}/>
                    </div>
                    <div className="d-flex row justify-content-between align-items-start text-start">
                        <div className="col-md-6 col-12">
                            <TagsPost tags={postSingle.tags}/>
                        </div>
                        <div className="col-md-6 col-12 pt-5">
                            <SocialMedia share={postSingle.share}/>
                        </div>
                    </div>
                    {/*<BannerADS/>*/}
                    <SimilarPost theme={props.theme} category={postSingle.category.slug ?? '-'}/>
                </div>
                <div className="col-md-3 col-12">
                    <SideBar title={t('latest') + ' ' + t('posts')} type={''}/>
                </div>
            </div>

            {/*<div className="p-2 mb-0 pb-0 pt-5">*/}
            {/*    <BannerADS/>*/}
            {/*</div>*/}

        </div>
);
}

export default SinglePage;