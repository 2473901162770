import React, {useState, useEffect} from 'react';
import PostApi from './../../api/post_api';
import SquareADS from "../ads/square_ads";
import Pagination from "../pagination";
import PaginationComponent from "../pagination";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

function PostsByTagSection(props) {

    const { t, i18n } = useTranslation()

    const {name} = useParams();

    const [posts, setPosts] = useState([]);
    const [pagination, setPagination] = useState([]);

    const setLocalData = (data) => {
        setPosts(data.posts)
        setPagination(data.pagination)
    }

    const fetchPostApi = (parameters) => {
        PostApi.getPosts(parameters, setLocalData)
    }

    useEffect(() => {
        fetchPostApi('?tag=' + name);
    }, [])

    return (
        <div className="container">
            <div className="text-start p-2">
                <h1 className={'lead display-1'}>#{
                    name.toUpperCase()
                }</h1>
            </div>

            <div className="d-flex flex-wrap">

                {
                    posts && posts.length > 0 && posts.map((post, index) => <div key={index} className="p-2 col-md-4 card-post">
                        <a href={'/' + post.category.name.en.toLowerCase() + '/' + post.slug} className={''}>
                            <div className="border border-dark text-start pb-4 shadow_">
                                <h4 className={'p-4'}><b className={'text-uppercase'}></b><span>{post.title.en}</span></h4>
                                <div className="cover">
                                    {post.image && <img src={post.image} alt="post-img" className={'img-fluid'}/>}
                                </div>
                                <p className={'p-4 pb-0'}>
                                    <div dangerouslySetInnerHTML={{__html: post.body.en.length > 100 ? post.body.en.substr(0, 100) + '...' : post.body.en}} />
                                </p>
                                <span className="text-secondary pt-4 p-2">
                                {post.data} <i>#{  i18n.language === 'en' ? post.category.name.en : post.category.name.ua}</i>
                                    </span>
                            </div>
                        </a>
                    </div>)
                }

                <div className="p-2 col-md-4">
                    <SquareADS />
                </div>

            </div>

            <PaginationComponent pagination={pagination} funcGetData={fetchPostApi} />
        </div>
    );
}

export default PostsByTagSection;