import Request from './request';

class PostApi {

    async getPosts (parameters, funcSetData) {
       await Request.getRequest('content/posts', parameters, funcSetData);
    }

    async getSinglePost(slug, funcSetData) {
       await Request.getRequest('content/post/' + slug, '', funcSetData);
    }

    async getTags (parameters, funcSetData) {
        await Request.getRequest('content/tags', parameters, funcSetData);
    }

    async getLinks (parameters, funcSetData) {
        await Request.getRequest('content/links', parameters, funcSetData);
    }
}

export default new PostApi();