import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";

function LoadMoreSection(props) {

    const fetchPostApi = (parameters) => {

    }

    const { t, i18n } = useTranslation()

    return (
        <div className="load-more">
            <div className="text-center">
                <span className={'text-uppercase'}><button onClick={props.funcLoadMore} className={'border border-dark p-2 mt-5 col-md-3 bg-white text-center shadow-1'}>{t('load')} {t('more')}</button></span>
            </div>
        </div>
    );
}

export default LoadMoreSection;