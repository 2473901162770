import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import PostApi from "../api/post_api";
import Helmet from "react-helmet";

function TagsPage(props) {

    const { t, i18n } = useTranslation()

    const [tags, setTags] = useState();

    const [search, setSearch] = useState("");

    const getTags = () => {
        try{
            PostApi.getTags('', (data) => setTags(data))
        }catch (e){
            console.log(e)
        }
    }

    const searchTag = (e) => {

        const search = e.target.value;
        setSearch(search)
    }

    useEffect(() => {
        getTags();
    }, [])

    return (
        <div className="container-fluid">

            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('tags')}</title>
                <link rel="canonical" href="https://blog.lukyanchuk.com/categories" />
                <meta name="description" content="posts page" />
                <meta name="keywords" content="GrowthUp, Blog, News, Dev, Learn, Study, Post, Tag, Tags Posts, Dev blog, News, Categories, Tags, developer blog" />
                <meta name="author" content="Yaroslav Lukyanchuk" />
                <meta property="article:tag" content="Blog" />
                <meta property="article:tag" content="News" />
                <meta property="article:tag" content="Posts" />
                <meta property="article:tag" content="Tags" />
                {
                    tags && tags.length > 1 && tags.filter((value) => {
                        console.log(value)
                        // return value;
                        if(search === ""){
                            return value;
                        }else if(value.toLowerCase().includes(search.toLocaleLowerCase())){
                            return value;
                        }
                    }).map((tag, index) =>
                        <meta property="article:tag" content={tag.split(',')[0]} />
                    )
                }
            </Helmet>

            <div className="container p-2 pt-5 vh-100">

                <h1 className={'lead display-5 mb-5 pb-5'}>{t('tags')}</h1>

                <input type="text" className={'form-control_ p-2 border-0 border-bottom display-6 w-100'}  value={search} onChange={searchTag} placeholder={t('search') + '...'}/>

                <div className="p-2 mt-5 text-start">
                    <div className={'p-2 lead d-flex flex-wrap'}>
                        {
                            tags && tags.length > 1 && tags.filter((value) => {
                                console.log(value)
                                // return value;
                                if(search === ""){
                                    return value;
                                }else if(value.toLowerCase().includes(search.toLocaleLowerCase())){
                                    return value;
                                }
                            }).map((tag, index) =>
                                <a key={index} href={'/tag/' + tag.split(',')[0]} className={'p-2 border rounded m-1'}>#{tag.split(',')[0]}</a>
                            )
                        }
                    </div>
                </div>

            </div>

        </div>
    );
}

export default TagsPage;