import React, {useEffect, useState} from 'react';

import SideBar from "../components/sidebar";
import Helmet from "react-helmet";
import BannerADS from "../components/ads/banner_ads";
import {useTranslation} from "react-i18next";
import CategoryAPI from "../api/category_api";

function CategoryPage() {

    const { t, i18n } = useTranslation();

    const [categories, setCategories] = useState();

    const setData = (data) => {
        // window.localStorage.setItem('category', data)
        setCategories(data);
    }

    const getCategories = () => {
        try{

            CategoryAPI.getCategory('', setData)

        }catch (e){
            console.log(e)
        }
    }

    useEffect(() => {
        // if(window.localStorage.getItem('category') != null){
        //     setData(window.localStorage.getItem('category'))
        //     console.log('local')
        // }else{
        //     getCategories();
        //     console.log('api')
        // }
        getCategories();

    }, [])

    return (
        <div className={'container'}>

            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('categories')}</title>
                <link rel="canonical" href="https://blog.lukyanchuk.com/categories" />
                <meta name="description" content="categories page" />
                <meta name="keywords" content="GrowthUp, Blog, News, Dev, Learn, Study, Post, Tag, Posts, Dev blog, News, Categories, Tags, developer blog" />
                <meta name="author" content="Yaroslav Lukyanchuk" />
                <meta property="article:tag" content="Blog" />
                <meta property="article:tag" content="News" />
                <meta property="article:tag" content="Posts" />
                <meta property="article:tag" content="Post" />
                <meta property="article:tag" content="Categories" />
                <meta property="article:tag" content="Category" />
                {
                    categories && categories.length > 0 && categories.map((category, index) => index !== 0 && <meta property="article:tag" content={category.name.en} />)
                }
                {
                    categories && categories.length > 0 && categories.map((category, index) => index !== 0 && <meta property="article:tag" content={category.name.ua} />)
                }
            </Helmet>

                <BannerADS />

                <h1 className={'lead display-1 p-4 pb-0'}>{t('categories')}</h1>
                <div className="category-post container d-flex row">

                    <div className="col-md-9 col-12">
                        <div className={'container d-flex flex-wrap'}>
                            {
                                categories && categories.length > 0 ? categories.map((category, index) => index !== 0 && <div className={'mt-5 col-md-6 p-2'}>
                                    <div key={index} className={'d-flex justify-content-between align-items-center border border-dark border-bottom- p-2'}>

                                        <a href={'/category/' + category.slug} className={'display-6 lead'}>
                                            {
                                                i18n.language === 'en' ? category.name.en : category.name.ua
                                            }
                                            <br/>
                                            <small className={'lead'}>
                                                {
                                                    category.posts_count
                                                }  {t('posts.count')}
                                            </small>

                                        </a>
                                        <small className={'lead text-secondary'}><a href={'/category/' + category.slug}>
                                            {t('browse.all')}
                                        </a></small>
                                    </div>
                                </div>) : 'Loading...'
                            }
                        </div>
                    </div>
                    <div className="col-md-3 col-12 pt-5">
                        <SideBar type={''} />
                        {/*<SideBar type={'Advertising'} title={'Advertising'} />*/}
                    </div>

                </div>

        </div>
    );
}

export default CategoryPage;