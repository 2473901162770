import React from 'react';

function SquareADS() {
    return (
        <div className={'d-flex align-items-center'}>

            {/*<a href="https://billing.hostpro.ua/aff.php?aff=7643"><img src="https://billing.hostpro.ua/partners/uk/2021/vps/300x250.png" alt="Hostpro" rel="nofollow" className={'img-fluid'}/></a>*/}

        </div>
    );
}

export default SquareADS;