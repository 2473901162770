import axios from "axios";
import URL from './config';

class Request {

    getRequest = async(url, parameters, funcSetData) => {
       console.log(URL(url) + parameters)
       await axios.get(URL(url) + parameters, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        }).then(response => {
            console.log(response.data.data)
            funcSetData(response.data.data);
            return JSON.stringify(response.data.data);
        }).catch(error => {
            console.log(error)
            return null;
        })
    }

    postRequest = async(url, parameters, funcSetData, data) => {
        console.log(URL(url) + parameters)
        await axios.post(URL(url) + parameters, data, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        }).then(response => {
            console.log(response.data.data)
            funcSetData(response.data.data);
            return JSON.stringify(response.data.data);
        }).catch(error => {
            console.log(error)
            return null;
        })
    }
}

export default new Request();