
const API_PRODUCTION = 'https://blogapi.lukyanchuk.com/';
const API_DEVELOP = 'http://127.0.0.1:8000/';

const SUFIX = 'api/';

const URL = (url) => {

    const originalURL = window.location.origin;
    console.log('originalURL ' + originalURL)
    const API = API_PRODUCTION;//originalURL === 'http://localhost:3000' ? API_DEVELOP : API_PRODUCTION;

    return API + SUFIX + url;
}

export default URL;