
import React from "react";

import Tamplate from "../../tamplate";
import Main from "../../pages/main";
import CategoryPage from "../../pages/category";
import Page404 from "../../pages/404";
import SiteMapPage from "../../pages/sitemap";
import InfoPage from "../../pages/info";
import PostsPage from "../../pages/posts";
import TagsPage from "../../pages/tags";
import InfoSectionPage from "../../pages/info_section";

function ListRoutes() {

    const routes = [
        {
            name: {
                ua: "Головна",
                en: "Home",
            },
            link: "/",
            child: <Tamplate child={<Main />} />,
            template: "Basic"
        },
        {
            name: {
                ua: "Пости",
                en: "Posts",
            },
            link: "/posts",
            child: <Tamplate child={<PostsPage/>} />,
            template: "Basic"
        },
        {
            name: {
                ua: "Категорії",
                en: "Categories",
            },
            link: "/categories",
            child: <Tamplate child={<CategoryPage />} />,
            template: "Basic"
        },
        {
            name: {
                ua: "Тегі",
                en: "Tags",
            },
            link: "/tags",
            child: <Tamplate child={<TagsPage />} />,
            template: "Basic"
        },
        {
            name: {
                ua: "Інформація",
                en: "Information",
            },
            link: "/information",
            child: <Tamplate child={<InfoPage />} />,
            template: "Basic"
        },
        {
            name: {
                ua: "Мапа сайту",
                en: "Site map",
            },
            link: "/site-map",
            child: <Tamplate child={<SiteMapPage />} />,
            template: "Basic"
        },
        {
            name: {
                ua: "404",
                en: "404",
            },
            link: "*",
            child: <Tamplate child={<Page404 />} />,
            template: "Nav"
        },
        {
            name: {
                ua: "Information Page",
                en: "Information Page",
            },
            link: "/information/p/",
            child: <Tamplate child={<InfoSectionPage />} />,
            template: "Basic"
        },
    ];

    return routes;
}

export default ListRoutes();