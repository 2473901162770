import React, {useEffect, useState} from 'react';

function CardPostRecently(props) {

    const post = props.post;

    return (
        <div className={"latest-post-card border-top border-dark pb-2 pt-2 " + props.theme + '-card'}>

            {/*<p>*/}
            {/*    <div dangerouslySetInnerHTML={{__html: post.body.en.length > 20 ? post.body.en.substr(0, 20) + '...' : post.body.en}} />*/}
            {/*</p>*/}
            <p className="text-secondary">
                                {post.data} <i>#{post.category.name.en}</i>
                            </p>
            <h5><b> <a  href={'/' + post.category.name.en.toLowerCase() + '/' + post.slug} className={'text-uppercase'}>{post.title.en} </a></b></h5>
        </div>
    );
}

export default CardPostRecently;