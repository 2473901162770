import React from 'react';
import {useTranslation} from "react-i18next";

function TagsPost(props) {

    const tags = props.tags;
    const { t, i18n } = useTranslation()

    const _tags = function(tags) {
        try {
            JSON.parse(tags).map((tag, index) => <span key={index} className={'p-1'}><a href={'/tag/' + tag} className={'tag'}>#{tag}</a></span>)
        } catch (e) {
            return tags.split(',').map((tag, index) => <span key={index} className={'p-1'}><a href={'/tag/' + tag} className={'tag'}>#{tag}</a></span>);
        }
    }

    return (
        <div className="tags-post container m-auto mt-5 text-start">
            <h5 className={'text-uppercase'}>{t('tags')}:</h5>
            <div className="d-flex flex-wrap">
                {
                    tags && _tags(tags)
                }
            </div>
        </div>
    );
}

export default TagsPost;