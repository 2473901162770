import React, {useEffect, useRef, useState} from 'react';
import NavLinks from "../components/routes/list";
import PostApi from '../api/post_api';
import CategoryAPI from "../api/category_api";
import {useTranslation} from "react-i18next";
import Helmet from "react-helmet";
import API from "../api/request";

function useEffectOnce(effect) {
    const effectFn = useRef(effect)
    const destroyFn = useRef()
    const effectCalled = useRef(false)
    const rendered = useRef(false)
    const [, refresh] = useState(0)

    if (effectCalled.current) {
        rendered.current = true
    }

    useEffect(() => {
        if (!effectCalled.current) {
            destroyFn.current = effectFn.current()
            effectCalled.current = true
        }

        refresh(1)

        return () => {
            if (rendered.current === false) return
            if (destroyFn.current) destroyFn.current()
        }
    }, [])
}

function SiteMapPage(props) {

    const { t, i18n } = useTranslation()

    const [posts, setPosts] = useState();
    const [categories, setCategories] = useState();
    const [tags, setTags] = useState();

    const getTags = () => {
        try{
            PostApi.getTags('', (data) => setTags(data))
        }catch (e){
            console.log(e)
        }
    }

    const getLinks = () => {
        try{
            PostApi.getLinks('', (data) => setPosts(data))
        }catch (e){
            console.log(e)
        }
    }

    const getCategories = () => {
        try{

            CategoryAPI.getCategory('', (data) => setCategories(data))

        }catch (e){
            console.log(e)
        }
    }


    // useEffect(() => {
    //     getTags();
    //     getLinks();
    //     getCategories();
    // }, [])

    useEffectOnce(() => {
        getTags();
        getLinks();
        getCategories();

        return () => {
            console.log("useEffectOnce exit");
        };
    })

    return (
        <div className="container-fluid">

            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('site-map')}</title>
                <link rel="canonical" href="https://blog.lukyanchuk.com/categories" />
                <meta name="description" content="site map page" />
                <meta name="keywords" content="GrowthUp, Blog, News, Dev, Learn, Study, Post, Tag, Posts, Dev blog, News, Categories, Tags, developer blog" />
                <meta name="author" content="Yaroslav Lukyanchuk" />
                <meta property="article:tag" content="Blog" />
                <meta property="article:tag" content="News" />
                <meta property="article:tag" content="Posts" />
                <meta property="article:tag" content="Inforamtion" />
                <meta property="article:tag" content="site map" />
                <meta property="article:tag" content="Category" />
                <meta property="article:tag" content="Tags" />
            </Helmet>

            <div className="container p-2 pt-5">

                <div className="mt-5 min-vh-100">
                    <h1 className={'lead display-1'}>{t('site-map')}</h1>

                    <div className="p-2 mt-5 text-start">
                        <h2>Basic</h2>
                        <small>({NavLinks?.length})</small>
                        <div className={'p-2 lead d-flex flex-wrap'}>

                            {
                                NavLinks.map((link, index) => link.template === 'Basic' &&
                                    <a key={index} href={link.link} className={'p-2'}>{i18n.language === 'en' ? link.name.en : link.name.ua}</a>
                                )
                            }
                        </div>
                    </div>

                    <div className="p-2 mt-5 text-start">
                        <h2>{t('categories')}</h2>
                        <small>({categories?.length})</small>
                        <div className={'p-2 lead d-flex flex-wrap'}>
                            {
                                categories && categories.map((category, index) =>
                                    <a key={index} href={category.slug} className={'p-2'}>{i18n.language === 'en' ? category.name.en : category.name.ua}</a>
                                )
                            }
                        </div>
                    </div>

                    <div className="p-2 mt-5 text-start">
                        <h2>{t('posts')}</h2>
                        <small>({posts?.length})</small>
                        <div className={'p-2 lead d-flex flex-wrap'}>
                            {
                                posts && posts.map((post, index) =>
                                    <a key={index} href={post.link} className={'p-2 m-1 border rounded border-dark'}>
                                        <h5 className="text-dark">
                                            {i18n.language === 'en' ? post.title.en : post.title.ua}
                                        </h5>
                                        <small className={'text-secondary'}>{post.data} #{
                                            i18n.language === 'en' ? post.category.en : post.category.ua
                                        }</small>
                                    </a>
                                )
                            }
                        </div>
                    </div>

                    <div className="p-2 mt-5 text-start">
                        <h2>{t('tags')}</h2>
                        <small>({tags?.length})</small>
                        <div className={'p-2 lead d-flex flex-wrap'}>
                            {
                                tags && tags.map((tag, index) =>
                                    <a key={index} href={'/tag/' + tag} className={'p-2'}>#{
                                        index+1 !== tags.length ? tag : tag.split(',')[0]
                                    }</a>
                                )
                            }
                        </div>
                    </div>
                </div>


            </div>

        </div>
    );
}

export default SiteMapPage;