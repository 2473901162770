class ThemeMode {

    constructor() {
        this.init();
    }

    init(){
        this.mode = localStorage.getItem('mode') ?? 'light';
    }

    loadInit(mode, callback){

        localStorage.setItem('mode', mode);

        this.init()

        callback();
    }

}

export default new ThemeMode();

