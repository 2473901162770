import React from 'react';
import {useTranslation} from "react-i18next";

function Lang() {

    const { t, i18n } = useTranslation()

    const changeLanguage = (event) => {
        i18n.changeLanguage(event.target.value)
        localStorage.setItem('lang', i18n.language);
    }

    return (<div>
        <div id="lang-switch">
            <div onChange={changeLanguage}>
                <ul className={'list-unstyled d-flex'}>
                    <li className={'p-1'}>
                        <label className="radio-img p-1 flag-language">
                            <input type="radio" value="en" name="language" />
                            {/*<img src="/images/uk.png" alt="English" width="20"/>*/}
                            <a>EN</a>
                        </label>
                    </li>
                    <li>
                        <label className="radio-img p-2 flag-language">/</label>
                    </li>
                    <li className={'p-1'}>
                        <label className="radio-img p-1 flag-language">
                            <input type="radio" value="ua" name="language"/>
                            {/*<img src="/images/ua.png" alt="Ukraine" width="20"/>*/}
                            <a>UA</a>
                        </label>
                    </li>
                </ul>
            </div>
        </div>
    </div>);
}

export default Lang;
