import React, {useEffect, useRef, useState} from 'react';
import PostApi from "../api/post_api";
import CardPost from "./card";
import {useTranslation} from "react-i18next";
import API from "../api/request";

function Section(props) {

    const title = props.title;
    const categorySlug = props.category;

    const [data, setData] = useState();

    const setLocalData = (data) => {
        setData(data.posts)
    }

    const getData = () => {
        PostApi.getPosts('?onPage=6&sort=DESC&category='+categorySlug, setLocalData)
        console.log('load from server: ' + data)
    }

    useEffect(()=>{
        getData()
    }, [])

    //TODO: cache get data

    const { t, i18n } = useTranslation()

    if(data && data.length === 0){
        return <div></div>;
    }

    return (
        <section className="section pt-2">


            <div className="border-top d-flex justify-content-between align-items-center border-dark mt-2 pb-2">

                <h2 className={'m-2 m-md-5 mb-0 display-3'}>{title ?? 'TITLE'}</h2>
                <div className="border- border-dark- p-md-2 p-1 text-center m-2 m-md-5 mb-0">

                    {
                        props.type === 'similar-post' ?
                            <a href=""> {props.search && <input type={'text'} placeholder={'Search...'} className={'p-2'} />}</a>
                            :
                            <span className={'shadow-1 text-uppercase border border-dark m-auto p-3'}><a href={'/category/' + categorySlug}>{t('browse.all')}</a></span>
                    }

                </div>
            </div>
            <div className="post-by-category border-top text-start border-dark pt-4">
                <div className="d-flex flex-wrap">
                    {
                        props.child
                    }

                        {
                            data && data.length > 0 && data.map((post, index) =>
                                <CardPost key={index} type={props.type} post={post}/>
                                )
                        }

                </div>
            </div>
        </section>
    );
}

export default Section;