import React, {useRef} from 'react';
import SideBar from "../components/sidebar";
import Helmet from "react-helmet";
import BannerADS from "../components/ads/banner_ads";
import {useParams} from "react-router";
import PostsByCategorySection from "../components/section/posts_by_category";

function CategoryPostsPage(props) {

    const {name} = useParams();

    return (
        <div className={'container'}>

            <Helmet>
                <meta charSet="utf-8" />
                <title>{name.toUpperCase()}</title>
                <link rel="canonical" href="https://blog.lukyanchuk.com/categories" />
                <meta name="description" content="posts by category page" />
                <meta name="keywords" content="GrowthUp, Blog, News, Dev, Learn, Study, Post, Tag, Posts, Dev blog, News, Categories, Tags, developer blog" />
                <meta name="author" content="Yaroslav Lukyanchuk" />
                <meta property="article:tag" content="Blog" />
                <meta property="article:tag" content="News" />
                <meta property="article:tag" content="Posts" />
                <meta property="article:tag" content={name} />
                <meta property="article:tag" content="Category" />
            </Helmet>

            <BannerADS />

            <div className="category-post container d-flex row">

                <div className="col-md-9 col-12">
                    <PostsByCategorySection name={name} />
                </div>
                <div className="col-md-3 col-12 pt-5">
                    <SideBar title={'Latest Posts'} type={''} category={name}/>
                    {/*<SideBar type={'Advertising'} title={'Advertising'} />*/}
                </div>

            </div>

        </div>
    );
}

export default CategoryPostsPage;